var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("h2", { style: { textAlign: "center" } }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("div", { staticClass: "dialog-body" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
        ]),
        _c("footer", [
          _c("div", { staticClass: "button-area" }, [
            _c(
              "button",
              {
                staticClass: "button cancel",
                on: { click: _vm.onNegativeClick },
              },
              [_vm._v(_vm._s(_vm.negative))]
            ),
            _c(
              "button",
              {
                staticClass: "button danger",
                on: { click: _vm.onPositiveClick },
              },
              [_vm._v(_vm._s(_vm.positive))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }